import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus[TransactionStatus["Ok"] = 0] = "Ok";
    TransactionStatus[TransactionStatus["Voided"] = 1] = "Voided";
    TransactionStatus[TransactionStatus["Changed"] = 2] = "Changed";
    TransactionStatus[TransactionStatus["OnHold"] = 3] = "OnHold";
})(TransactionStatus || (TransactionStatus = {}));
export const TransactionStatusMap = new Map([
    [TransactionStatus.Ok, 'Global.Options.TransactionStatus.Ok'],
    [TransactionStatus.Changed, 'Global.Options.TransactionStatus.Changed'],
    [TransactionStatus.Voided, 'Global.Options.TransactionStatus.Voided'],
]);
let TransactionStatusService = class TransactionStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TransactionStatusMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
    has(key) {
        return this._data.has(key);
    }
    getStatusClass(status) {
        switch (status) {
            case TransactionStatus.Ok:
                return 'text-success';
            case TransactionStatus.Voided:
                return 'text-danger';
            case TransactionStatus.Changed:
            case TransactionStatus.OnHold:
                return 'text-warning';
            default:
                return '';
        }
    }
};
TransactionStatusService.ctorParameters = () => [
    { type: TranslateService }
];
TransactionStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TransactionStatusService);
export { TransactionStatusService };
